@use 'styles/theme/theme' as theme;

.castingBanner {
  position: relative;
  background: theme.$orangeLight;
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 240px;
  padding: 0 100px 0 80px;
  align-items: center;
  @media screen and (max-width: 1024px) {
    padding: 0;
  }

  @media screen and (max-width: 600px) {
    padding: 20px 40px !important;
  }

  @media screen and (max-width: 960px) {
    height: 230px;
  }

  &__logoBlock {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    padding: 12px;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  &__bgBlurredShade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 489.277px;
    transform: translate(65%, 0%);
    background: theme.$white;
    filter: blur(69.1500015258789px);
    width: 450px;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &__image {
    z-index: 1;
    display: block;
    margin-right: 20px;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  &__textArea {
    z-index: 1;

    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
    }

    &_listRow {
      display: block;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    &_mainBlock {
      display: flex;
      align-items: flex-start;
      gap: 30px;

      @media screen and (max-width: 1280px) {
        gap: 20px;
      }

      @media screen and (max-width: 900px) {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }

      .title {
        font-size: 64px;
        font-family: 'Playfair Display';
        margin-top: -10px;
        color: theme.$black;
        @media screen and (max-width: 1024px) {
          font-size: 45px;
        }
        @media screen and (max-width: 900px) {
          font-size: 36px;
        }
      }

      .subTextBlock {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        @media screen and (max-width: 900px) {
          align-items: center;
        }

        &__subTitle {
          font-size: 26px;
          color: theme.$orange;
          @media screen and (max-width: 1024px) {
            font-size: 20px;
          }
          @media screen and (max-width: 900px) {
            font-size: 16px;
            text-align: center;
          }
        }

        &__otherText {
          font-size: 18px;
          color: theme.$black;
          ul {
            display: flex;
            align-items: center;
            list-style: none;
            padding: 0;
            li:not(:last-child)::after {
              content: '';
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: theme.$black;
              margin-left: 8px;
              margin-right: 8px;
              vertical-align: middle;
            }
          }
          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }
          @media screen and (max-width: 900px) {
            font-size: 12px;
          }
        }

        &__listRow {
          display: none;
          &_list {
            display: flex;
            list-style-type: none;
            padding: 0;
            margin-bottom: 22px;
          }
          @media screen and (max-width: 900px) {
            display: flex;
            gap: 10px;
          }
        }

        &__tryNowBtn {
          @media screen and (max-width: 900px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-bottom: 22px;
    @media screen and (max-width: 900px) {
      margin-bottom: 0;
    }
  }
  .listItems {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px;
    color: theme.$grey12;
    font-weight: theme.$font-weight-medium;
    @media screen and (max-width: 1064px) {
      font-size: 14px;
    }
    @media screen and (max-width: 900px) {
      font-size: 10px;
      color: theme.$grey12;
    }
    &::after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: theme.$orange;
      margin: 0 8px;

      @media screen and (max-width: 1024px) {
        width: 4px;
        height: 4px;
        margin: 0 4px;
      }
    }
    &:last-child::after {
      display: none;
    }
  }
}
